/**
 * Indicates if the given permission is active or not
 * @param permissionName {String} Name of the permission
 * @returns {boolean} true if the permission is active
 */
function hasPermission(permissionName) {
  var foundPermission = permissions.find(function (permission) {
    return permission.name === permissionName;
  });
  return foundPermission ? foundPermission.active : false
}

/**
 * Sets a cookie by adding together the cookiename, the cookie value, and the expires string.
 * To remove cookie use exdays = -1.
 * @param cname Name of the cookie.
 * @param cvalue Value of the cookie.
 * @param exdays Number of days until the cookie should expire (default 365 days).
 */
function setCookie(cname, cvalue, exdays, samesite) {
  var d = new Date();
  exdays = exdays || 365;
  samesite = samesite || 'lax';
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  var expires = 'expires=' + d.toUTCString();
  var secure = location.protocol === 'http:' ? '' : 'secure;';
  var samesiteValue = 'samesite=' + samesite;
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/;' + samesiteValue + ';' + secure;
}

/**
 * Gets the value of a cookie given its name
 * @param name
 * @returns the cookie value
 */
function getCookie(name) {
  var cookieValue = null;
  if (document.cookie && document.cookie != '') {
    var cookies = document.cookie.split(';');
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) == (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

/**
 * Downloads a file from browser.
 *
 * @param blob {Blob} File-like object of raw data that is going to be downloaded
 * @param filename {String} Filename
 */
function downloadFile(blob, filename) {
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    // If IE is used we have the `msSaveBlob` method for downloading the report
    window.navigator.msSaveBlob(blob, filename);

  } else {
    // If a browser distinct from IE is used, an anchor element must be created and clicked
    var blobURL = window.URL.createObjectURL(blob);
    var anchor = document.createElement('a');
    anchor.style.display = 'none';
    anchor.href = blobURL;
    anchor.download = filename;

    // In Firefox is necessary to include the anchor into the document
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
    window.URL.revokeObjectURL(blobURL);
  }
}

/**
 * Check if the param reasonId is "other"
 *
 * @param reasonId
 * @return if the reason is "other" {boolean}
 */
function checkOtherReasonSelected(reasonId) {
  var OLD_OTHER_REASON_ID = '99';
  var OTHER_REASON_ID = 'other';

  return (reasonId === OTHER_REASON_ID || reasonId === OLD_OTHER_REASON_ID);
}

/**
 * Depending on the rate's direction (due to currency's weight), we must multiply or divided by it.
 */
function applyRateToAmountDependingOnRateDirection(amount, sellCurrency, buyCurrency, rate, rateSymbol) {
  var convertedAmount;
  if (rateSymbol === sellCurrency + buyCurrency) {
    convertedAmount = amount.times(rate);
  } else {
    convertedAmount = amount.dividedBy(rate);
  }
  return convertedAmount;
}
